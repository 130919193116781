<template>
  <div class="">
    <div class="mt-16 xl:mt-0">
      <div class="flex justify-between items-center">
        <h1
          class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
        >
          تعديل بريد
        </h1>
      </div>

      <div class="align-middle inline-block min-w-full mt-4">
        <errormessage :error="errors"></errormessage>

        <div class="grid grid-cols-3 gap-8">
          <form
            @submit.prevent="updateMail"
            class="col-span-2 bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-4 shadow border dark:border-slate-800 sm:rounded-md"
          >
            <div class="">
              <section class="mt-2 grid md:grid-cols-3 gap-6">
                <div class="col-span-2">
                  <div
                    class="input"
                    :class="{ invalid: $v.mail_data.title.$error }"
                  >
                    <label
                      for="mail_data_title"
                      class="block text-sm font-medium"
                    >
                      العنوان<span class="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="mail_data_title"
                      placeholder="أكتب العنوان."
                      v-model="mail_data.title"
                      class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @blur="$v.mail_data.title.$touch()"
                    />
                    <p
                      v-if="!$v.mail_data.title.minLength"
                      class="text-xs text-red-500 mt-2"
                    >
                      يجب أن يحتوي العنوان على
                      {{ $v.mail_data.title.$params.minLength.min }} أحرف على
                      الأقل.
                    </p>
                  </div>

                  <div
                    class="input mt-6"
                    :class="{ invalid: $v.mail_data.summary.$error }"
                  >
                    <label
                      for="mail_data_summary"
                      class="block text-sm font-medium"
                    >
                      الملخص <span class="text-red-500">*</span>
                    </label>

                    <textarea
                      id="mail_data_summary"
                      cols="30"
                      rows="10"
                      placeholder="أكتب الملخص ."
                      v-model="mail_data.summary"
                      class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @blur="$v.mail_data.summary.$touch()"
                    ></textarea>

                    <p
                      v-if="!$v.mail_data.summary.minLength"
                      class="text-xs text-red-500 mt-2"
                    >
                      يجب أن يحتوي الملخص على
                      {{ $v.mail_data.summary.$params.minLength.min }} أحرف على
                      الأقل.
                    </p>
                  </div>
                </div>

                <div class="grid gap-6">
                  <div class="input">
                    <!-- :class="{ invalid: $v.mail_data.day.$error , invalid: $v.mail_data.month.$error, invalid: $v.mail_data.year.$error }" -->

                    <div class="flex justify-between items-center">
                      <div
                        class="input"
                        :class="{ invalid: $v.mail_data.day.$error }"
                      >
                        <label
                          for="mail_data_date_of_birth_day"
                          class="text-sm font-medium"
                        >
                          اليوم <span class="text-red-500">*</span>
                        </label>

                        <input
                          type="number"
                          id="mail_data_date_of_birth_day"
                          placeholder=".."
                          v-model="mail_data.day"
                          class="w-16 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          @blur="$v.mail_data.day.$touch()"
                        />
                      </div>

                      <div
                        class="input"
                        :class="{ invalid: $v.mail_data.month.$error }"
                      >
                        <label
                          for="mail_data_date_of_birth_month"
                          class="text-sm font-medium"
                        >
                          الشهر <span class="text-red-500">*</span>
                        </label>

                        <input
                          type="number"
                          id="mail_data_date_of_birth_month"
                          placeholder=".."
                          v-model="mail_data.month"
                          class="w-16 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          @blur="$v.mail_data.month.$touch()"
                        />
                      </div>

                      <div
                        class="input"
                        :class="{ invalid: $v.mail_data.year.$error }"
                      >
                        <label
                          for="mail_data_date_of_birth_year"
                          class="text-sm font-medium"
                        >
                          السنة <span class="text-red-500">*</span>
                        </label>

                        <input
                          type="number"
                          id="mail_data_date_of_birth_year"
                          placeholder="...."
                          v-model="mail_data.year"
                          class="w-24 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          @blur="$v.mail_data.year.$touch()"
                        />
                      </div>
                    </div>
                  </div>




                  <!--  -->



                  <div
                    class="input"
                    :class="{ invalid: $v.mail_data.mail_references_number.$error }"
                  >
                    <label
                      for="mail_data_mail_references_number"
                      class="block text-sm font-medium"
                    >
                      رقم البريد <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="mail_data_mail_references_number"
                      placeholder="أكتب رقم البريد ."
                      v-model="mail_data.mail_references_number"
                      class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @blur="$v.mail_data.mail_references_number.$touch()"
                    />
      
                  </div>



              





                  <div class="input">
                    <label for="sender_side" class="block text-sm font-medium">
                      المرسل <span class="text-red-500">*</span>
                    </label>

                    {{ mail_data.externalSection_name }}
                  </div>
                </div>
              </section>
            </div>

            <div class="text-white w-full mt-8">
              <div
                class="inline w-full"
                :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
              >
                <button
                  :disabled="$v.$invalid"
                  type="submit"
                  class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                  :class="
                    $v.$invalid
                      ? 'bg-gray-600'
                      : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  "
                >
                  تعديل
                </button>
              </div>
            </div>
          </form>

          <div
            class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:px-6 sm:py-4 shadow border dark:border-slate-800 sm:rounded-md"
          >
            <div class="flex items-center justify-between">
              <div class="">المستندات</div>

              <div class="text-white">
                <button
                  type="button"
                  @click="toggle_upload_docs_model = true"
                  class="bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                >
                  تحميل وثائق
                </button>
              </div>
            </div>

            <div v-if="there_are_docs" class="">
              <div class="mt-4 relative h-80">
                <img
                  id="docs_id"
                  :src="docs_path"
                  alt=""
                  class="h-full w-full"
                />

                <div
                  class="absolute z-10 inset-0 flex justify-between items-center px-4 text-white font-black"
                >
                  <div class="flex justify-center w-full">
                    <button
                      @click="open_docs_model()"
                      class="w-32 h-8 rounded bg-gray-400"
                    >
                      تكبير
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="flex justify-between items-end px-4 text-slate-500 dark:text-slate-400 font-black mt-4"
              >
                <div class="text-black">
                  <button
                    v-if="docs_page_number > 1"
                    @click="prev_doc"
                    class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                  >
                    &#x276E;
                  </button>
                </div>

                <div class="">{{ docs_page_number }} / {{ total_of_docs }}</div>

                <div class="text-black">
                  <button
                    v-if="docs_page_number < total_of_docs"
                    @click="next_doc"
                    class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                  >
                    &#x276F;
                  </button>
                </div>
              </div>
            </div>

            <div v-else class="flex justify-center items-center h-full">
              لايوجد مستندات
            </div>
          </div>

         
        </div>
      </div>
    </div>

    <div
      v-if="toggle_upload_docs_model"
      class="absolute z-50 inset-0 bg-black bg-opacity-70"
    >
      <div class="flex justify-center items-center h-full">
        <div class="bg-white w-2/3 h-96">
          <div class="flex justify-between items-center border-b-2 py-2">
            <div class="px-4 text-black">تحميل وثائق جديدة</div>

            <div class="cursor-pointer text-red-600">
              <button
                @click.prevent="toggle_upload_docs_model = false"
                type="button"
                class="w-12 py-2 font-bold focus:outline-none"
              >
                X
              </button>
            </div>
          </div>

          <div class="w-full mx-auto mt-2">
            <div class="flex items-center justify-center px-4">
              <input type="file" @change="upload" multiple class="" />

              <div
                class="w-full flex justify-end text-white"
                :class="
                  last_of_image == [] ? 'cursor-not-allowed' : 'cursor-pointer'
                "
              >
                <button
                  @click.prevent="addDocmentMail()"
                  v-if="last_of_image != []"
                  type="submit"
                  class="border border-transparent rounded-md shadow-sm py-2 px-16 text-sm font-medium focus:outline-none"
                  :class="
                    last_of_image == []
                      ? 'bg-gray-600'
                      : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  "
                >
                  تحميل
                </button>
              </div>
            </div>

            <div
              class="grid grid-cols-7 gap-4 mt-4 overflow-x-hidden overflow-y-auto h-64 p-4"
            >
              <div
                v-for="(image, index) in last_of_image"
                :key="index"
                class="w-32 h-32"
              >
                <img
                  :src="image.base46"
                  alt=""
                  class="w-full h-full border-2 border-black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="toggle_docs_model"
      class="absolute z-40 inset-0 bg-black bg-opacity-70 min-h-full top-0"
    >
      <div
        class="cursor-pointer text-white font-bold absolute z-50 flex justify-between items-center top-6 px-6 w-full"
      >
        <div class="">مستندات البريد رقم {{ mail_data.mailNumber }}</div>
        <div class="">
          <button
            @click.prevent="toggle_docs_model = false"
            type="button"
            class="focus:outline-none bg-red-500 hover:bg-red-600 w-10 h-10 rounded-full"
          >
            X
          </button>
        </div>
      </div>

      <div
        class="flex justify-center items-start h-full max-h-95 overflow-y-auto"
      >
        <div class="bg-white w-2/3 h-screen top-0 mt-0">
          <div class="w-full mx-auto mt-2 relative h-screen">
            <div class="mt-4" style="height: 1500px">
              <VueSignaturePad
                id="signature"
                :options="signatureOptions"
                ref="signaturePad"
              />
            </div>

            <div
              class="flex justify-between items-end px-4 text-slate-500 dark:text-slate-400 font-black mt-4 sticky bottom-0 bg-white p-2 rounded-md border border-gray-700"
            >
              <div class="text-black">
                <button
                  v-if="docs_page_number > 1"
                  @click="prev_doc"
                  class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                >
                  &#x276E;
                </button>
              </div>

              <div class="flex items-center">
                <div>{{ docs_page_number }} / {{ total_of_docs }}</div>
                <button
                  @click="clearSignature"
                  class="focus:outline-none py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer mx-2"
                >
                  مسح التعديلات
                </button>
                <button
                  @click="saveSignature"
                  class="focus:outline-none py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer"
                >
                  حفظ التعديلات
                </button>
              </div>

              <div class="text-black">
                <button
                  v-if="docs_page_number < total_of_docs"
                  @click="next_doc"
                  class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                >
                  &#x276F;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import MailService from "@/services/MailService";
import { between, minLength, required } from "vuelidate/lib/validators";

export default {
  components: {
    errormessage,
  },
  data() {
    return {
      toggle_upload_docs_model: false,
      last_of_image: [],
      attachmentName: "",
      sender_side: "",

      sender_side_data: {},

      mail_data: {
        id: this.$route.params.id,
        mail_number: "",
        title: "",
        mail_references_number:'',
        summary: "",
        office_type: this.$store.state.auth.administrator.type,
        create_by: this.$store.state.auth.administrator.id,
        update_by: "",
        year: "",
        month: "",
        day: "",
        type: "",
        externalSection_id: "",
        externalSection_name: "",
      },

      errors: null,

      docs_page_number: 1,
      docs_Page_size: 1,
      docs_path: "",
      docs_id: "",
      total_of_docs: 0,

      there_are_docs: false,

      toggle_docs_model: false,

      signatureOptions: {
        dotSize: (0.5 + 2.5) / 2.5,
        maxWidth: 2,
      },
      docWidth: `${window.visualViewport.scale * 100}%`,
      docHeight: `${window.visualViewport.scale * 100}%`,

      base46_after_signature: "",

      signature_docs_page_number: 1,
      signature_docs_Page_size: 1,
      signature_docs_path: "",
      signature_docs_id: "",
      signature_total_of_docs: 0,

      signature_there_are_docs: false,
    };
  },

  watch: {
    sender_side: function () {
      this.mail_data.externalSection_id = this.sender_side;
      this.getsenderSide();
    },
  },

  created() {
    this.getMailById();
  },
  validations: {
    mail_data: {
      summary: {
        required,
        minLength: minLength(2),
      },
      title: {
        required,
        minLength: minLength(2),
      },
      day: {
        between: between(1, 31),
        required,
      },

      month: {
        between: between(1, 12),
        required,
      },
      mail_references_number: {
        required,
      },
      
      year: {
        between: between(1800, 3000),
        required,
      },
      externalSection_id: {
        required,
      },
    },

    // sender_side: {
    //   required,
    // },
  },
  methods: {
    get_docs_signature() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getDocsSignature(
        this.mail_data.id,
        this.signature_docs_page_number,
        this.signature_docs_Page_size
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.signature_there_are_docs = true;

            this.signature_docs_path = res.data.list[0].path;
            this.signature_docs_id = res.data.list[0].id;
            this.signature_total_of_docs = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();
          this.signature_there_are_docs = false;
          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    signature_next_doc() {
      this.signature_docs_page_number++;
      this.get_docs_signature();
    },

    signature_prev_doc() {
      this.signature_docs_page_number--;
      this.get_docs_signature();
    },

    onBrowserZoomChange() {
      if (this.toggle_docs_model) {
        this.docWidth = `${window.visualViewport.scale * 100}%`;
        this.docHeight = `${window.visualViewport.scale * 100}%`;
        this.$refs.signaturePad.fromDataURL(this.docs_path);
      }
    },

    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      console.log(isEmpty);
      console.log(data);

      this.base46_after_signature = data;

      this.save_doc_after_signature();
    },

    save_doc_after_signature() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data_to_send = {
        mail_id: this.mail_data.id,
        base64: [
          {
            base46: this.base46_after_signature,
          },
        ],
      };

      MailService.addSignatureDocmentMail(data_to_send)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });

            console.log(resp);
            this.toggle_docs_model = false;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.toggle_docs_model = false;

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature();

      this.$refs.signaturePad.fromDataURL(this.docs_path);
    },

    next_doc() {
      this.docs_page_number++;
      this.getDocs();
    },

    prev_doc() {
      this.docs_page_number--;
      this.getDocs();
    },

    open_docs_model() {
      this.toggle_docs_model = true;
      this.getDocs_hhhh();
    },

    getDocs_hhhh() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getDocs(
        this.mail_data.id,
        this.docs_page_number,
        this.docs_Page_size
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.there_are_docs = true;

            this.docs_path = res.data.list[0].path;
            this.docs_id = res.data.list[0].id;
            this.total_of_docs = res.data.total;

            this.$refs.signaturePad.fromDataURL(this.docs_path);
          }, 10);
        })
        .catch(() => {
          loader.hide();
          this.there_are_docs = false;
          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    getDocs() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getDocs(
        this.mail_data.id,
        this.docs_page_number,
        this.docs_Page_size
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.there_are_docs = true;

            this.docs_path = res.data.list[0].path;
            this.docs_id = res.data.list[0].id;
            this.total_of_docs = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();
          this.there_are_docs = false;
          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    addDocmentMail() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        mail_id: this.mail_data.id,
        base64: this.last_of_image,
      };

      MailService.addDocmentMail(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.toggle_upload_docs_model = false;
            this.getDocs();
            console.log(resp);
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.toggle_upload_docs_model = false;
          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    upload(file) {
      let ValidFile = true;

      for (let index = 0; index < event.target.files.length; index++) {
        if (ValidFile) {
          this.attachmentName = file;
          if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[index]);
            reader.onload = (event) => {
              this.last_of_image.push({
                base46: event.target.result,
              });
            };
          } else {
            this.last_of_image = null;
          }
        }
      }
    },

    getMailById() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getMailById(this.$route.params.id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.mail_data = res.data;

            this.getDocs();
            this.get_docs_signature();
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    updateMail() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        id: this.mail_data.id,
        mailNumber: this.mail_data.mailNumber,
        title: this.mail_data.title,
        summary: this.mail_data.summary,
        update_by: this.mail_data.update_by,
        year: Number(this.mail_data.year),
        month: Number(this.mail_data.month),
        day: Number(this.mail_data.day),
        externalSection_id: this.mail_data.externalSection_id,
      };

      MailService.updateMail(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });

            this.mail_data.id = resp.data.id;
            this.mail_data.mail_number = resp.data.mail_number;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getsenderSide() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getsenderSide(this.sender_side)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            console.log(res);
            this.sender_side_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>

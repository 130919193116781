import * as API from "./API";

import store from "../store";

export default {






    trining_is_done(id) {
        return API.apiClient.put(`/api/Municipal/change_tranning_state?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getMunicipalsWithoutPagination() {
        return API.apiClient.get(`/api/Municipal/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getMunicipalsSearch(page_number, page_size, name, id) {
        return API.apiClient.get(`/api/Municipal/search?page_number=${page_number}&page_size=${page_size}&name=${name}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getMunicipals(page_number, page_size) {
        return API.apiClient.get(`/api/Municipal/GetAllMunicipal?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipal(id) {
        return API.apiClient.get(`/api/Municipal/GetByID?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createMunicipal(municipal) {
        return API.apiClient.post(`/api/Municipal/Add`, municipal, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteMunicipal(id) {
        return API.apiClient.delete(`/api/Municipal/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateMunicipal(municipal) {
        return API.apiClient.put(`/api/Municipal/Update`, municipal, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getAdministratorsForMuncipal(page_number, page_size, id) {
        return API.apiClient.get(`/api/Administrator/get_users_by_muncipal_id?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};
import * as API from "./API";

import store from "../store";

export default {
    // getMuncipalOrderWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getMuncipalOrder(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requests/get_all_requests_for_municipal?page_number=${page_number}&page_size=${page_size}&municipal_id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createHealthCenterOrder(order) {
        return API.apiClient.post(`/api/Health_center_municipality_requests/Add`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteOrder(id) {
        return API.apiClient.delete(`/api/Health_center_municipality_requests/block?id=${id}&state=3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    orderApproval(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/change_order_state?id=${id}&state=2`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    OrderReject(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/Reject_Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    quantification_order(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/quantification_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    update_quantification_order(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/Update_quantification_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipalStorageApproved(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requests/Get_all_requests_by_order_state?page_number=${page_number}&page_size=${page_size}&municipal_id=${id}&order_state=3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    order_delivery_to_vaccination_center(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/confirm_receipt_of_quantity`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },







    getMonthlyVaccinationMovement(id, vaccine_id) {
        return API.apiClient.get(`/api/Order/monthly_vaccination_movement?id=${id}&vaccine_id=${vaccine_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },































    // getMuncipalOrderById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createMuncipalOrder(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },








    getOrderReadyToDelivery(page_number, page_size, id) {
        return API.apiClient.get(`/api/Order/all_orders_Delevery_from_Medical_supplies?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    confirmReceipt(id) {
        return API.apiClient.get(`/api/Order/The_municipality_received?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // updateMuncipalOrder(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};
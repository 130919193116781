/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from 'axios';
// import store from "../store";

export const apiClient = axios.create({
    baseURL: "https://express-mail-back.gt-dev.ly/",
    // baseURL: "https://localhost:44384/",
    // baseURL: 'http://contabo.growthtech.ly/mail/',
    // baseURL: "https://localhost:44306/",

    withCredentials: false, // required to handle the CSRF token
    // headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${store.state.auth.accessToken}`
    //         // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    // }
});

export const login = axios.create({
    baseURL: "https://express-mail-back.gt-dev.ly/",
    // baseURL: "https://localhost:44384/",
    // baseURL: 'http://contabo.growthtech.ly/mail/',
    // baseURL: "https://localhost:44306/",

    withCredentials: false, // required to handle the CSRF token
});
<template>
  <div class="">
    <div class="mt-16 xl:mt-0">
      <div class="flex justify-between items-center">
        <h1
          class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
        >
          البريد الوارد
        </h1>

        <router-link
          title="إضافة"
          :to="{
            name: 'add-sent-mail',
          }"
          class="w-28 md:w-48 text-center py-2 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          إضافة بريد جديد +
        </router-link>
      </div>

      <div class="mt-4 w-full relative">
        <div class="h-8 w-full flex items-center justify-between">
          <div class="grid grid-cols-4 gap-8">
            <div class="input">
              <label for="filter_mail_number" class="block text-sm font-medium">
                رقم البريد
              </label>
              <input
                type="tel"
                id="filter_mail_number"
                placeholder="أكتب رقم البريد."
                v-model="filter_mail_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label for="mail_title" class="block text-sm font-medium">
                العنوان
              </label>
              <input
                type="text"
                id="mail_title"
                placeholder="أكتب العنوان."
                v-model="filter_mail_title"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>


            <div class="input">
              <label for="date" class="block text-sm font-medium">
                التاريخ
              </label>
              <input
                type="date"
                id="date"
                placeholder="أكتب التاريخ."
                v-model="filter_date"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>



          </div>

          <div
            class="flex items-center w-full md:w-auto cursor-pointer text-white"
          >
            <button
              v-if="in_search"
              @click="cancel_filter()"
              class="flex items-center justify-center w-28 md:w-48 text-center py-2 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <span class="ml-2"> إلغاء الفرز </span>

              <svg
                class="h-6 w-6 stroke-current"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6974 6.69598L6.6974 18.696"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.6974 6.69598L18.6974 18.696"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <button
              @click="do_search()"
              type="button"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              بحث
            </button>
          </div>
        </div>
      </div>

      <div class="py-2 align-middle inline-block min-w-full mt-6">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <div
              class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
            >
              <div scope="col" class="w-1/12 px-4 py-3 hidden lg:block">
                رقم البريد
              </div>

              <div scope="col" class="w-6/12 px-2 py-3">العنوان</div>

              <div scope="col" class="w-3/12 px-2 py-3 hidden md:block">
                المرسل
              </div>

              <div scope="col" class="w-2/12 px-2 py-3">الإجراءات</div>
            </div>

            <div
              v-if="total == 0"
              class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
            >
              <span v-if="in_search">
                لاتوجد نتائج متوافقة معا اختيارات الفرز.
              </span>

              <span v-else> لايوجد بريد </span>
            </div>

            <div
              v-else
              v-for="(mail, personIdx) in mail_data"
              :key="mail.id"
              class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
              :class="
                personIdx % 2 === 0
                  ? 'bg-white dark:bg-slate-800'
                  : 'bg-gray-50 dark:bg-slate-800'
              "
            >
              <div
                class="w-1/12  md:flex justify-between items-center whitespace-nowrap text-sm font-medium hidden "
              >

                <div class="w-3/12 h-full" :class="
                personIdx % 2 === 0
                  ? 'bg-red-500'
                  : 'bg-green-500'
              "> </div>
                <div class="w-9/12 pr-4">
                  {{ mail.mailNumber }}
                </div>
              </div>

              <div
                class="w-6/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
              >
                {{ mail.title }}
              </div>

              <div class="w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate">
                {{ mail.externalSection_name }}
              </div>

              <div class="w-2/12 px-2 py-4">
                <div class="w-full flex items-center justify-between">
                  <router-link
                    title="عرض التفاصيل"
                    :to="{
                      name: 'show-inbox-mail',
                      params: {
                        id: mail.id,
                      },
                    }"
                    class="mx-2 px-1"
                  >
                    <svg
                      class="h-6 w-6 stroke-current hover:text-green-600"
                      width="800px"
                      height="800px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Edit / Show">
                        <g id="Vector">
                          <path
                            d="M3.5868 13.7788C5.36623 15.5478 8.46953 17.9999 12.0002 17.9999C15.5308 17.9999 18.6335 15.5478 20.413 13.7788C20.8823 13.3123 21.1177 13.0782 21.2671 12.6201C21.3738 12.2933 21.3738 11.7067 21.2671 11.3799C21.1177 10.9218 20.8823 10.6877 20.413 10.2211C18.6335 8.45208 15.5308 6 12.0002 6C8.46953 6 5.36623 8.45208 3.5868 10.2211C3.11714 10.688 2.88229 10.9216 2.7328 11.3799C2.62618 11.7067 2.62618 12.2933 2.7328 12.6201C2.88229 13.0784 3.11714 13.3119 3.5868 13.7788Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </router-link>

                  <button
                    title="المستندات"
                    class="px-1 cursor-pointer"
                    @click="open_docs_model(mail.id, mail.mailNumber)"
                  >
                    <svg
                      class="h-6 w-6 stroke-current hover:text-blue-600"
                      width="800px"
                      height="800px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.18 8.03933L18.6435 7.57589C19.4113 6.80804 20.6563 6.80804 21.4241 7.57589C22.192 8.34374 22.192 9.58868 21.4241 10.3565L20.9607 10.82M18.18 8.03933C18.18 8.03933 18.238 9.02414 19.1069 9.89309C19.9759 10.762 20.9607 10.82 20.9607 10.82M18.18 8.03933L13.9194 12.2999C13.6308 12.5885 13.4865 12.7328 13.3624 12.8919C13.2161 13.0796 13.0906 13.2827 12.9882 13.4975C12.9014 13.6797 12.8368 13.8732 12.7078 14.2604L12.2946 15.5L12.1609 15.901M20.9607 10.82L16.7001 15.0806C16.4115 15.3692 16.2672 15.5135 16.1081 15.6376C15.9204 15.7839 15.7173 15.9094 15.5025 16.0118C15.3203 16.0986 15.1268 16.1632 14.7396 16.2922L13.5 16.7054L13.099 16.8391M13.099 16.8391L12.6979 16.9728C12.5074 17.0363 12.2973 16.9867 12.1553 16.8447C12.0133 16.7027 11.9637 16.4926 12.0272 16.3021L12.1609 15.901M13.099 16.8391L12.1609 15.901"
                        stroke-width="1.5"
                      />
                      <path
                        d="M8 13H10.5"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8 9H14.5"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8 17H9.5"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M3 14V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157M21 14C21 17.7712 21 19.6569 19.8284 20.8284M4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284M19.8284 20.8284C20.7715 19.8853 20.9554 18.4796 20.9913 16"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>

                  <button title="حذف" class="px-1" @click="deleteMail(mail.id)">
                    <svg
                      class="h-6 w-6 stroke-current hover:text-red-600"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.80444 6.50317H5.80444H21.8044"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </table>
        </div>
      </div>

      <div
        class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
      >
        <pagination
          dir="rtl"
          v-model="page_number"
          :per-page="page_size"
          :records="total"
          @paginate="getInboxMail"
          class="z-10"
        />
      </div>
    </div>

    <div
      v-if="toggle_docs_model"
      class="absolute z-40 inset-0 bg-black bg-opacity-70 min-h-full"
    >
      <div
        class="cursor-pointer text-white font-bold absolute z-50 flex justify-between items-center top-6 px-6 w-full"
      >
        <div class="">مستندات البريد رقم {{ mail_number_for_doc }}</div>
        <div class="">
          <button
            @click.prevent="toggle_docs_model = false"
            type="button"
            class="focus:outline-none bg-red-500 hover:bg-red-600 w-10 h-10 rounded-full"
          >
            X
          </button>
        </div>
      </div>

      <div
        class="flex justify-center items-center h-full max-h-95 overflow-y-auto"
      >
        <div class="bg-white w-2/3 h-screen">
          <div class="w-full mx-auto mt-2 relative h-screen">
            <div
              class="mt-4 mx-auto"
              style="height: 842px; width: 595px"
              :style="{
                backgroundImage: `url(${docs_path})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }"
            >
              <VueSignaturePad
                id="signature"
                :options="signatureOptions"
                ref="signaturePad"
              />

              <!-- <div
                class="absolute z-10 inset-0 flex justify-between items-center px-4 text-red-600 font-black"
              >
                <div class="">
                  <button class="w-8 h-8 rounded bg-gray-400">&#x276E;</button>
                </div>

                <div class="">1</div>

                <div class="">
                  <button class="w-8 h-8 rounded bg-gray-400">&#x276F;</button>
                </div>
              </div> -->
            </div>

            <div
              class="flex justify-between items-end px-4 text-slate-500 dark:text-slate-400 font-black mt-4 sticky bottom-0 bg-white p-2 rounded-md border border-gray-700"
            >
              <div class="text-black">
                <button
                  v-if="docs_page_number > 1"
                  @click="prev_doc"
                  class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                >
                  &#x276E;
                </button>
              </div>

              <div class="flex items-center">
                <div>{{ docs_page_number }} / {{ total_of_docs }}</div>
                <button
                  @click="clearSignature"
                  class="focus:outline-none py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer mx-2"
                >
                  مسح التعديلات
                </button>
                <button
                  @click="saveSignature"
                  class="focus:outline-none py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer"
                >
                  حفظ التعديلات
                </button>
              </div>

              <div class="text-black">
                <button
                  v-if="docs_page_number < total_of_docs"
                  @click="next_doc"
                  class="w-8 h-8 rounded bg-gray-400 hover:bg-gray-500 cursor-pointer"
                >
                  &#x276F;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailService from '@/services/MailService';

export default {
  computed: {},

  data() {
    return {
      mail_data: {},

      filter_box: false,

      filter_search: '',

      filter_mail_number: '',
      filter_mail_title: '',
      filter_date:'',

      page_number: 1,
      page_size: 10,
      total: 0,

      in_search: false,

      docs_page_number: 1,
      docs_Page_size: 1,
      docs_path: '',
      docs_id: '',
      total_of_docs: 0,

      mail_id_for_doc: '',
      mail_number_for_doc: '',
      toggle_docs_model: false,

      signatureOptions: {
        dotSize: (0.5 + 2.5) / 2.5,
        maxWidth: 2,
      },

      base46_after_signature: '',
    };
  },

  created() {
    this.getInboxMail();
    // window.addEventListener('resize', this.onBrowserZoomChange);
  },

  watch: {
    // filter_search: function() {
    //     this.getInboxMail()
    // },
    // filter_national_number: function() {
    //     this.getInboxMail()
    // },
    //     filter_administrator_id: function() {
    //         if(this.filter_administrator_id == 0){
    //             this.filter_administrator_id = '';
    //         }
    //         this.getInboxMail()
    //     },
  },

  methods: {
    open_docs_model(id, number) {
      this.mail_id_for_doc = id;
      this.mail_number_for_doc = number;

      this.toggle_docs_model = true;

      this.getDocs();
    },

    // onBrowserZoomChange() {
    //   if (this.toggle_docs_model) {
    //     this.docWidth = `${window.visualViewport.scale * 100}%`;
    //     this.docHeight = `${window.visualViewport.scale * 100}%`;
    //     this.$refs.signaturePad.fromDataURL(this.docs_path);
    //   }
    // },

    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      console.log(isEmpty);
      console.log(data);

      this.base46_after_signature = data;

      this.save_doc_after_signature();
    },

    save_doc_after_signature() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data_to_send = {
        mail_id: this.mail_id_for_doc,
        base64: [
          {
            base46: this.base46_after_signature,
          },
        ],
      };

      MailService.addSignatureDocmentMail(data_to_send)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: 'success',
              text: resp.data.message,
            });

            console.log(resp);
            this.toggle_docs_model = false;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.toggle_docs_model = false;

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message,
          });
        });
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature();

      this.$refs.signaturePad.fromDataURL(this.docs_path);
    },

    next_doc() {
      this.docs_page_number++;
      this.getDocs();
    },

    prev_doc() {
      this.docs_page_number--;
      this.getDocs();
    },

    getDocs() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getDocs(
        this.mail_id_for_doc,
        this.docs_page_number,
        this.docs_Page_size
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.docs_path = res.data.list[0].path;
            this.docs_id = res.data.list[0].id;
            this.total_of_docs = res.data.total;
            // this.$refs.signaturePad.fromDataURL(this.docs_path);
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message,
          });
        });
    },

    cancel_filter() {
      this.in_search = false;

      this.filter_mail_number = '';

      this.filter_mail_title = '';

      this.page_number = 1;
      this.page_size = 10;

      this.getInboxMail();
    },

    do_search() {
      this.page_number = 1;
      this.getInboxMail();
      this.filter_box = !this.filter_box;
      this.in_search = true;
    },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },

    getInboxMail() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MailService.getInboxMail(
        this.page_number,
        this.page_size,
        Number(this.$store.state.auth.administrator.type),
        this.filter_mail_number,
        this.filter_mail_title
      )

        // this.filter_mail_title,
        // this.filter_mail_number,

        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.mail_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();

          this.mail_data = {};
          this.total = 0;
        });
    },

    deleteMail(id) {
      this.$swal
        .fire({
          title: 'هل أنت متأكد؟',
          text: '!لن تتمكن من التراجع عن هذا',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'إلغاء',
          confirmButtonColor: '#3085d6',
          confirmButtonText: '!نعم ، احذفها',
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            MailService.deleteMail(id)
              .then((resp) => {
                let i = this.mail_data.map((item) => item.id).indexOf(id); // find index of your object
                this.mail_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: 'success',
                  confirmButtonText: 'حسنا',
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: 'error',
                  title: '...عذرا',
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>

import * as API from "./API";



export default {

    login(payload) {
        return API.login.post("/api/User/Login", payload);
    },






    // async login(payload) {
    //     await API.apiClient.get("/sanctum/csrf-cookie");
    //     return API.apiClient.post("/api/v1/users/auth/signin", payload);
    // },
    async registerUser(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        return API.apiClient.post("/api/v1/users/auth/signup", payload);
    },



    logout() {
        return API.apiClient.post("/api/v1/users/auth/signout");
    },
    // async forgotPassword(payload) {
    //     await API.apiClient.get("/sanctum/csrf-cookie");
    //     return API.apiClient.post("/forgot-password", payload);
    // },
    getAuthUser() {
        return API.apiClient.get("/api/v1/user");
    },

    // async resetPassword(payload) {
    //     await API.apiClient.get("/sanctum/csrf-cookie");
    //     return API.apiClient.post("/reset-password", payload);
    // },
    // sendVerification(payload) {
    //     return API.apiClient.post("/email/verification-notification", payload);
    // },
    // updateUser(payload) {
    //     return API.apiClient.put("/user/profile-information", payload);
    // },
};
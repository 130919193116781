<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        الردود الجاهزة
      </h1>

      <router-link
        title="إضافة"
        :to="{ name: 'add-ready-reply' }"
        class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        إضافة +
      </router-link>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
              الرقم
            </div>
            <div scope="col" class="w-4/12 px-6 py-3">العنوان</div>
            <div scope="col" class="w-6/12 px-6 py-3 hidden md:block">النص</div>

            <div scope="col" class="w-1/12 px-6 py-3">الإجراءات</div>
          </div>
          <div
            v-for="(administrator, personIdx) in ready_reply_data"
            :key="administrator.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >
            <div
              class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden lg:block"
            >
              {{ personIdx + 1 }}
            </div>
            <div class="w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate">
              {{ administrator.title }}
            </div>
            <div
              class="w-6/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ administrator.summary }}
            </div>

            <div class="w-1/12 px-6 py-4">
              <div class="w-full flex items-center justify-between">
                <router-link
                  title="تعديل"
                  :to="{
                    name: 'edit-ready-reply',
                    params: { id: administrator.id },
                  }"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-blue-600"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_number"
        :per-page="page_size"
        :records="total"
        @paginate="getReadyReplys"
        class="z-10"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import ReadyReplyService from "@/services/ReadyReplyService";

export default {
  // components: { pagination: LaravelVuePagination },
  computed: {},
  data() {
    return {
      ready_reply_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,


    };
  },
  created() {
    this.getReadyReplys();
  },
  methods: {
  
    getReadyReplys() {
      let loader = this.$loading.show({
        loader: this.loader,
      });
      ReadyReplyService.getReadyReplys(this.page_number, this.page_size)
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.ready_reply_data = res.data.list;

            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    deleteAdministrator(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            ReadyReplyService.deleteAdministrator(id)
              .then((resp) => {
                let i = this.ready_reply_data
                  .map((item) => item.id)
                  .indexOf(id); // find index of your object
                this.ready_reply_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  /* margin-left: .5rem;
        margin-right: .5rem;*/
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>

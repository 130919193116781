var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-16 xl:mt-0"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h1',{staticClass:"text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"},[_vm._v(" الردود الجاهزة ")]),_c('router-link',{staticClass:"w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",attrs:{"title":"إضافة","to":{ name: 'add-ready-reply' }}},[_vm._v(" إضافة + ")])],1),_c('div',{staticClass:"py-2 align-middle inline-block min-w-full mt-6"},[_c('div',{staticClass:"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_vm._m(0),_vm._l((_vm.ready_reply_data),function(administrator,personIdx){return _c('div',{key:administrator.id,staticClass:"hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400",class:personIdx % 2 === 0
              ? 'bg-white dark:bg-slate-800'
              : 'bg-gray-50 dark:bg-slate-800'},[_c('div',{staticClass:"w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden lg:block"},[_vm._v(" "+_vm._s(personIdx + 1)+" ")]),_c('div',{staticClass:"w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate"},[_vm._v(" "+_vm._s(administrator.title)+" ")]),_c('div',{staticClass:"w-6/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"},[_vm._v(" "+_vm._s(administrator.summary)+" ")]),_c('div',{staticClass:"w-1/12 px-6 py-4"},[_c('div',{staticClass:"w-full flex items-center justify-between"},[_c('router-link',{staticClass:"mx-2",attrs:{"title":"تعديل","to":{
                  name: 'edit-ready-reply',
                  params: { id: administrator.id },
                }}},[_c('svg',{staticClass:"h-6 w-6 stroke-current hover:text-blue-600",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)])])})],2)])]),_c('div',{staticClass:"flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"},[_c('pagination',{staticClass:"z-10",attrs:{"dir":"rtl","per-page":_vm.page_size,"records":_vm.total},on:{"paginate":_vm.getReadyReplys},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"},[_c('div',{staticClass:"w-1/12 px-6 py-3 hidden lg:block",attrs:{"scope":"col"}},[_vm._v(" الرقم ")]),_c('div',{staticClass:"w-4/12 px-6 py-3",attrs:{"scope":"col"}},[_vm._v("العنوان")]),_c('div',{staticClass:"w-6/12 px-6 py-3 hidden md:block",attrs:{"scope":"col"}},[_vm._v("النص")]),_c('div',{staticClass:"w-1/12 px-6 py-3",attrs:{"scope":"col"}},[_vm._v("الإجراءات")])])
}]

export { render, staticRenderFns }
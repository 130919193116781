import * as API from "./API";

import store from "../store";

export default {


    createReadyReply(ReadyReply) {
        return API.apiClient.post(`/api/readyReply`, ReadyReply, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },
    getReadyReplyById(id) {
        return API.apiClient.get(`/api/readyReply?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },
    deleteReadyReply(id) {
        return API.apiClient.delete(`/api/readyReply?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },
    getReadyReplys(page_number, page_size) {
        return API.apiClient.get(`/api/readyReply/GetALL?page_number=${page_number}&Page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },
    getReadyReplysWithoutPagination() {
        return API.apiClient.get(`/api/readyReply/Get_All`);
    },














    getReadyReplysByRole(type) {
        return API.apiClient.get(`/api/ReadyReply/GetuserByFiltter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAllReadyReplysByRole(type) {
        return API.apiClient.get(`/api/ReadyReply/get_all_user_by_filtter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },







    resetPassword(data) {
        return API.apiClient.post(`/api/ReadyReply/rest_password`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getRoles() {
        return API.apiClient.get(`/api/ReadyReply/GetRoles`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getPermission() {
        return API.apiClient.get(`/api/ReadyReply/GetAllpermission`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getReadyReply(id) {
        return API.apiClient.get(`/api/ReadyReply/GetUserById?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateReadyReply(ReadyReply) {
        return API.apiClient.put(`/api/ReadyReply/Update`, ReadyReply, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};
import * as API from "./API";

import store from "../store";

export default {

    getsenderSide(perent) {
        return API.apiClient.get(`/api/External_sections/GetALLSub?perent=${perent}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createMail(mail) {
        return API.apiClient.post(`/api/Mail`, mail, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteMail(id) {
        return API.apiClient.delete(`/api/Mail?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMailById(id) {
        return API.apiClient.get(`/api/Mail?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateMail(mail) {
        return API.apiClient.put(`/api/Mail`, mail, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getSentMail(page_number, page_size, office_type, mail_number, titel) {
        return API.apiClient.get(`/api/Mail/GetsendedMail?page_number=${page_number}&Page_size=${page_size}&office_type=${office_type}&mail_number=${mail_number}&titel=${titel}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },
    getMailWithoutPagination() {
        return API.apiClient.get(`/api/User/Get_All`);
    },

    getInboxMail(page_number, page_size, office_type, mail_number, titel) {
        return API.apiClient.get(`/api/Mail/GetinboxMail?page_number=${page_number}&Page_size=${page_size}&office_type=${office_type}&mail_number=${mail_number}&titel=${titel}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    addDocmentMail(docs) {
        return API.apiClient.post(`/api/MailAttachments`, docs, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getDocs(id, page_number, page_size) {
        return API.apiClient.get(`/api/MailAttachments/Get_All?id=${id}&page_number=${page_number}&Page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    addSignatureDocmentMail(docs) {
        return API.apiClient.post(`/api/signatureAttchmen`, docs, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    getDocsSignature(id, page_number, page_size) {
        return API.apiClient.get(`/api/signatureAttchmen/Get_All?id=${id}&page_number=${page_number}&Page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },












    getAdministratorsByRole(type) {
        return API.apiClient.get(`/api/Administrator/GetuserByFiltter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAllAdministratorsByRole(type) {
        return API.apiClient.get(`/api/Administrator/get_all_user_by_filtter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },







    resetPassword(data) {
        return API.apiClient.post(`/api/Administrator/rest_password`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getRoles() {
        return API.apiClient.get(`/api/Administrator/GetRoles`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getPermission() {
        return API.apiClient.get(`/api/Administrator/GetAllpermission`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAdministrator(id) {
        return API.apiClient.get(`/api/Administrator/GetUserById?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateAdministrator(administrator) {
        return API.apiClient.put(`/api/Administrator/Update`, administrator, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};
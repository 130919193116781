<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                المسجلين
            </h1>

            <!-- <router-link
                title="إضافة"
                :to="{ name: 'addCitizenByMunicipal', params: { municipal_id:  municipal_id } }"
                class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                إضافة +
            </router-link>  -->
        </div>


        <!-- <div class="mt-4 flex items-center justify-between w-full relative">  
            <div class="h-8">
                <button @click="toggle_filter()" class="flex items-center hover:text-blue-600 cursor-pointer w-32">
                    <svg class="h-6 w-6 fill-current " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                    </svg>
                    <span class="mr-2">
                        فرز
                    </span>
                </button>
            </div>

            <div class="hidden xl:flex xl:ml-0 h-8">
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        id="search-field"
                        class="block h-full bg-gray-50 dark:bg-slate-900 w-full border-transparent py-2 pr-8 dark:text-gray-400 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="بحث...."
                        type="search"
                        name="search"
                        v-model="filter_search"
                    />
                </div>
            </div>

            <transition
                enter-active-class="transition ease-in-out duration-200"
                enter-class="transform opacity-0 scale-y-0"
                enter-to-class="transform opacity-100 scale-y-100"
                leave-active-class="transition ease-in-out duration-75"
                leave-class="transform opacity-100 scale-y-100"
                leave-to-class="transform opacity-0 scale-y-0"
            >

                <div v-if="filter_box" class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8">

                    <div class="grid grid-cols-4 gap-8">
                        <div class="">
                            <label for="medical_supply_id" class="block text-sm font-medium ">
                                اختر المسؤول 
                            </label>
                            <select
                                id="administrator_id"
                                name="administrator_id"
                                v-model="filter_administrator_id"
                                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option  disabled selected>المسؤولين</option>

                                <option v-bind:value="0">
                                    الكل
                                </option>

                                <option
                                    v-for="administrator in administrators_data"
                                    :key="administrator.id"
                                    v-bind:value="administrator.id"
                                >
                                    {{ administrator.full_name }}
                                </option>

                            </select>
                        </div>
                    </div>

                </div>

            </transition>
        </div> -->


        <div class="py-2 align-middle inline-block min-w-full mt-6">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                        <div scope="col" class="w-1/12 px-4 py-3 hidden lg:block">
                            الرقم
                        </div>
                        <div scope="col" class="w-7/12 md:w-3/12 px-2 py-3">
                            الاسم
                        </div>
                        <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                            رقم المنظومة
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                            رقم القيد
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                            البلدية
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                            المركز الصحي
                        </div>

                        <div scope="col" class="w-5/12 md:w-2/12 px-2 py-3">
                            الإجراءات
                        </div>
                    </div>
                    <div v-for="(citizen, personIdx) in citizens_data" :key="citizen.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-4 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                            {{ personIdx+1 }}
                        </div>
                        <div class="w-7/12 md:w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate">
                            {{ citizen.first_name }} {{ citizen.middle_name }} {{ citizen.last_name }} 
                        </div>
                        <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ citizen.citizen_number }}
                        </div>

                        <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ citizen.registration_number }}
                        </div>

                        <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ citizen.muncipal_name }}
                        </div>

                        <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ citizen.helth_Center_name }}
                        </div>

                        <div class="w-5/12 md:w-2/12 px-2 py-4">
                            <div class="w-full flex items-center justify-between">
                                <button title="حظر" class="">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-red-600"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M4.92993 4.93005L19.0699 19.0701"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>

                                <router-link title="العائلة" :to="{ name: 'citizenFamily', params: { id: citizen.id, registration_number:citizen.registration_number },}" class="mx-2">
                                    <svg class="h-6 w-6  fill-current hover:text-blue-600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        width="79.538px" height="79.538px" viewBox="0 0 79.538 79.538" style="enable-background:new 0 0 79.538 79.538;"
                                        xml:space="preserve">
                                        <g>
                                            <path d="M28.635,32.496c0,2.02-1.639,3.661-3.664,3.661c-2.025,0-3.669-1.642-3.669-3.661
                                                c0-2.03,1.644-3.666,3.669-3.666C26.996,28.83,28.635,30.466,28.635,32.496z M25.359,57.584l0.785,13.515
                                                c0,0.477,0.386,0.865,0.857,0.865c0.479,0,0.859-0.389,0.859-0.865l0.738-13.515h2.392l-2.473-15.151l4.974,9.906
                                                c0.187,0.439,0.689,0.647,1.129,0.455c0.435-0.181,0.642-0.684,0.451-1.123c0,0-3.648-9.864-4.549-11.703
                                                c-0.891-1.838-1.781-2.957-5.202-2.957h-0.699c-3.415,0-4.306,1.118-5.207,2.951c-0.896,1.844-4.557,11.702-4.557,11.702
                                                c-0.179,0.44,0.023,0.948,0.458,1.129c0.44,0.192,0.942-0.021,1.129-0.455l4.979-9.906l-2.475,15.151h2.39l0.743,13.515
                                                c0,0.477,0.386,0.865,0.86,0.865c0.481,0,0.862-0.389,0.862-0.865l0.737-13.515H25.359z M54.582,28.82
                                                c-2.029,0-3.671,1.636-3.671,3.666c0,2.02,1.646,3.656,3.671,3.656c2.02,0,3.65-1.636,3.65-3.656
                                                C58.232,30.456,56.602,28.82,54.582,28.82z M58.378,50.392l0.243-7.974l4.474,9.911c0.187,0.44,0.688,0.643,1.129,0.456
                                                c0.436-0.182,0.647-0.684,0.461-1.129c0,0-3.153-9.859-4.055-11.703c-0.896-1.833-2.283-2.952-5.706-2.952h-0.699
                                                c-3.412,0-4.815,1.119-5.711,2.952c-0.901,1.843-4.052,11.703-4.052,11.703c-0.185,0.445,0.022,0.947,0.458,1.129
                                                c0.44,0.187,0.942-0.021,1.129-0.456l4.479-9.911l0.249,7.974l-0.71,20.023c-0.057,0.798,0.544,1.486,1.331,1.538
                                                c0.042,0,0.078,0.006,0.104,0.006c0.751,0,1.383-0.575,1.439-1.336l1.632-15.488l1.631,15.488c0.052,0.761,0.684,1.336,1.439,1.336
                                                c0.031,0,0.067,0,0.099-0.006c0.797-0.052,1.388-0.74,1.336-1.538L58.378,50.392L58.378,50.392z M39.77,51.096
                                                c-1.24,0-2.247,1.005-2.247,2.247c0,1.243,1.007,2.242,2.247,2.242c1.24,0,2.252-0.999,2.252-2.242
                                                C42.022,52.101,41.01,51.096,39.77,51.096z M39.77,64.337c0,0,1.012,7.031,1.012,7.037c0.132,0.336,0.461,0.585,0.854,0.585
                                                c0.409,0,0.743-0.27,0.865-0.638c0.01-0.016,0.031-0.435,0.01-0.502l-0.414-8.637l0.127-2.962c0.116-1.01,0.137-0.715,1.289-1.398
                                                c1.171-0.704,3.167-3.79,3.26-3.945c0.096-0.15,0.282-0.425,0.318-0.596c0.055-0.315-0.147-0.606-0.455-0.668
                                                c-0.238-0.042-0.436,0.083-0.583,0.249c-0.155,0.16-2.449,3.215-4.173,3.51c-0.381-0.057-0.8-0.259-1.893-0.259h-0.435
                                                c-1.093,0-1.507,0.202-1.893,0.259c-1.725-0.295-4.019-3.35-4.174-3.51c-0.147-0.166-0.344-0.291-0.577-0.249
                                                c-0.308,0.062-0.51,0.357-0.451,0.668c0.031,0.171,0.218,0.445,0.313,0.596c0.096,0.155,2.094,3.241,3.259,3.945
                                                c1.152,0.684,1.173,0.389,1.29,1.398l0.122,2.962l-0.409,8.637c-0.021,0.072,0,0.486,0.01,0.502
                                                c0.122,0.368,0.45,0.638,0.865,0.638c0.388,0,0.722-0.249,0.854-0.585C38.763,71.368,39.77,64.337,39.77,64.337 M78.909,36.747
                                                c1.108-1.584,0.714-3.774-0.881-4.883L45.613,9.313c-3.329-2.319-8.357-2.319-11.682,0L1.509,31.864
                                                c-1.592,1.108-1.988,3.299-0.88,4.883c1.108,1.59,3.291,1.989,4.883,0.881l32.417-22.551c0.914-0.632,2.763-0.632,3.677,0
                                                l32.415,22.551c0.611,0.424,1.315,0.632,1.999,0.632C77.138,38.254,78.23,37.726,78.909,36.747z"/>
                                        </g>

                                    </svg>
                                </router-link>

                                <router-link title="تعديل" :to="{ name: 'editCitizenByMunicipal', params: { id: citizen.id, municipal_id:  municipal_id },}" class="mx-2">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-blue-600"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </router-link>

                                <button title="حذف" class="" @click="deleteCitizen(citizen.id)">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-red-600"
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.80444 6.50317H5.80444H21.8044"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </table>
            </div>
        </div>

       <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative">
            <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="getCitizens"
                class="z-10"
            />
        </div>
    </div>
</template>

<script>
import CitizensService from "@/services/CitizensService";
import AdministratorsService from "@/services/AdministratorsService";

export default {
    computed: {},

    data() {
        return {
            citizens_data: {},
            administrators_data: {},
            page_number: 1,
            page_size: 10,
            total: 0,

            filter_box: false,

            filter_search: '',
            filter_administrator_id: '',


            municipal_id: this.$route.params.municipal_id,
        };
    },

    created() {
        this.getCitizens();
        this.getAdministrators();
    },

    // watch: {
    //     filter_search: function() {
    //         this.getCitizens()
    //     },
    //     filter_administrator_id: function() {
    //         if(this.filter_administrator_id == 0){
    //             this.filter_administrator_id = '';
    //         }
    //         this.getCitizens()
    //     },
    // },

    methods: {
        toggle_filter(){
            this.filter_box = !this.filter_box;
        },

        getAdministrators() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            AdministratorsService.getAllAdministratorsByRole(3)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.administrators_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        getCitizens() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            CitizensService.getCitizensByMunicipal(
                this.page_number,
                this.page_size,
                this.municipal_id
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        this.citizens_data = res.data.list;
                        this.total = res.data.total;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        deleteCitizen(id) {
            this.$swal
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "!لن تتمكن من التراجع عن هذا",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "إلغاء",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "!نعم ، احذفها",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let loader = this.$loading.show({
                            loader: this.loader,
                        });

                        CitizensService.deleteCitizen(id)
                            .then((resp) => {
                                let i = this.citizens_data
                                    .map((item) => item.id)
                                    .indexOf(id); // find index of your object
                                this.citizens_data.splice(i, 1);
                                loader.hide();
                                this.$swal.fire({
                                    text: resp.data.message,
                                    icon: "success",
                                    confirmButtonText: "حسنا",
                                });
                            })
                            .catch((err) => {
                                loader.hide();

                                this.$swal.fire({
                                    icon: "error",
                                    title: "...عذرا",
                                    text: err.response.data.errors.name,
                                });
                            });
                    }
                });
        },
    },
};
</script>


<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: .5rem;
        margin-right: .5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>

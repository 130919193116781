var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full mt-6"},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('div',{staticClass:"col-span-2"},[_c('GmapMap',{staticClass:"mt-4",staticStyle:{"height":"900px"},attrs:{"center":{ lat: 27, lng: 17 },"zoom":5.2,"map-type-id":"terrain"}},[_vm._l((_vm.markers_location_of_health_center),function(m,index){return _c('GmapMarker',{key:index,staticClass:"relative",attrs:{"icon":require('@../../../src/assets/health_center.png'),"position":m.position,"clickable":true,"draggable":false},on:{"click":function($event){return _vm.openInfoWindow(m, 1)}}},[_c('div',{staticClass:"bg-red-600 w-24 h-24"},[_vm._v(" "+_vm._s(_vm.openedMarkerID)+"sssssssssss ")])])}),(_vm.selected_location_health_center !== null)?_c('gmap-info-window',{attrs:{"position":{
              lat: _vm.selected_location_health_center.position.lat,
              lng: _vm.selected_location_health_center.position.lng,
            },"opened":_vm.info_box_open_health_center},on:{"closeclick":function($event){return _vm.closeInfoWindow(1)}}},[_c('div',{staticClass:"infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"},[_c('h2',{attrs:{"id":"infoWindow-location"}},[_vm._v(" "+_vm._s(_vm.selected_location_health_center.name)+" ")])])]):_vm._e(),_vm._l((_vm.markers_location_of_muncipal),function(m,index){return _c('GmapMarker',{key:'i'+index,staticClass:"relative",attrs:{"position":m.position,"icon":require('@../../../src/assets/muncipal.png'),"clickable":true,"draggable":false},on:{"click":function($event){return _vm.openInfoWindow(m, 2)}}},[_c('div',{staticClass:"bg-red-600 w-24 h-24"},[_vm._v(" "+_vm._s(_vm.openedMarkerID)+" ")])])}),(_vm.selected_location_muncipal !== null)?_c('gmap-info-window',{attrs:{"position":{
              lat: _vm.selected_location_muncipal.position.lat,
              lng: _vm.selected_location_muncipal.position.lng,
            },"opened":_vm.info_box_open_muncipal},on:{"closeclick":function($event){return _vm.closeInfoWindow(2)}}},[_c('div',{staticClass:"infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"},[_c('h2',{attrs:{"id":"infoWindow-location"}},[_vm._v(" "+_vm._s(_vm.selected_location_muncipal.name)+" ")])])]):_vm._e(),_vm._l((_vm.markers_location_medical_supplies),function(m,index){return _c('GmapMarker',{key:index,staticClass:"relative",attrs:{"position":m.position,"icon":require('@../../../src/assets/medical_supplies.png'),"clickable":true,"draggable":false},on:{"click":function($event){return _vm.openInfoWindow(m, 3)}}},[_c('div',{staticClass:"bg-red-600 w-24 h-24"},[_vm._v(" "+_vm._s(_vm.openedMarkerID)+" ")])])}),(_vm.selected_location_medical_supplies !== null)?_c('gmap-info-window',{attrs:{"position":{
              lat: _vm.selected_location_medical_supplies.position.lat,
              lng: _vm.selected_location_medical_supplies.position.lng,
            },"opened":_vm.info_box_open_medical_supplies},on:{"closeclick":function($event){return _vm.closeInfoWindow(3)}}},[_c('div',{staticClass:"infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"},[_c('h2',{attrs:{"id":"infoWindow-location"}},[_vm._v(" "+_vm._s(_vm.selected_location_medical_supplies.name)+" ")])])]):_vm._e()],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
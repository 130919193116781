<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة رد جاهز
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        @submit.prevent="addReadyReply"
         autocomplete="off"
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-3 gap-8">
            <div
            class="input"
            :class="{ invalid: $v.ready_reply_data.title.$error }"
            >
            <label
                for="ready_reply_data_title"
                class="block text-sm font-medium"
            >
            العنوان
            </label>
            <input
                type="text"
                id="ready_reply_data_title"
                placeholder="أكتب الاسم."
                v-model="ready_reply_data.title"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.ready_reply_data.title.$touch()"
            />
            <p
                v-if="!$v.ready_reply_data.title.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                {{ $v.ready_reply_data.title.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input col-span-2"
            :class="{ invalid: $v.ready_reply_data.summary.$error }"
            >
            <label
                for="ready_reply_data_summaryx"
                class="block text-sm font-medium"
            >
                النص
            </label>
            <input
              autocomplete="off"
              
                type="text"
                id="ready_reply_data_summaryx"
                placeholder="أكتب النص."
                v-model="ready_reply_data.summary"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.ready_reply_data.summary.$touch()"
            />
            <p
                v-if="!$v.ready_reply_data.summary.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي النص على
                {{ $v.ready_reply_data.summary.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>




      
          
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              إضافة
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required, minLength  } from "vuelidate/lib/validators";
import ReadyReplyService from "@/services/ReadyReplyService";

export default {
  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,
      ready_reply_data: {
        summary: "",
        title: "",
      
      },

     
      // loader: "dots",
    };
  },
  created() {

    this.ready_reply_data.summary = ""
    this.ready_reply_data.title = ""
   


  },
  validations: {
    ready_reply_data: {
      title: {
        required,
        minLength: minLength(2),
      },
      summary: {
        required,
        minLength: minLength(2),
      },
      
    },
  },
  methods: {
   

    addReadyReply() {
        let loader = this.$loading.show({
            loader: this.loader,
        });

        let data = {
          summary: this.ready_reply_data.summary,
          title: this.ready_reply_data.title,
        };

        loader.hide();

      ReadyReplyService.createReadyReply(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({ name: "ready-reply" });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>

<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة مسؤول
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        @submit.prevent="addAdministrator"
         autocomplete="off"
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-3 gap-8">
            <div
            class="input"
            :class="{ invalid: $v.administrator.full_name.$error }"
            >
            <label
                for="administrator_full_name"
                class="block text-sm font-medium"
            >
                الاسم
            </label>
            <input
                type="text"
                id="administrator_full_name"
                placeholder="أكتب الاسم."
                v-model="administrator.full_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.full_name.$touch()"
            />
            <p
                v-if="!$v.administrator.full_name.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                {{ $v.administrator.full_name.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input"
            :class="{ invalid: $v.administrator.username.$error }"
            >
            <label
                for="administrator_usernamex"
                class="block text-sm font-medium"
            >
                اسم المستخدم
            </label>
            <input
              autocomplete="off"
              
                type="text"
                id="administrator_usernamex"
                placeholder="أكتب اسم المستخدم."
                v-model="administrator.username"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.username.$touch()"
            />
            <p
                v-if="!$v.administrator.username.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                {{ $v.administrator.username.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>




            <div
                class="input"
                :class="{ invalid: $v.administrator.type.$error }"
              >
                <fieldset>
                  <legend
                    for="citizen_type"
                    class="block text-sm font-medium"
                  >
                    الصفة <span class="text-red-500">*</span>
                  </legend>
                  <div class="mt-4 flex">
                    <div class="flex items-center w-32">
                      <input
                        id="father"
                        name="citizen_type"
                        v-model="administrator.type"
                        value="1"
                        type="radio"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        @blur="$v.administrator.type.$touch()"
                      />
                      <label
                        for="father"
                        class="mr-2 block text-sm font-medium"
                      >
                        الرئيس
                      </label>
                    </div>
                    <div class="flex items-center w-32">
                      <input
                        id="mother"
                        name="mother"
                        v-model="administrator.type"
                        value="2"
                        type="radio"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        @blur="$v.administrator.type.$touch()"
                      />
                      <label
                        for="mother"
                        class="mr-2 block text-sm font-medium"
                      >
                        مدير المكتب 
                      </label>
                    </div>
                   
                  </div>
                </fieldset>
              </div>



            <div
            class="input"
            :class="{ invalid: $v.administrator.password.$error }"
            >
            <label
                for="administrator_password"
                class="block text-sm font-medium"
            >
                كلمة المرور
            </label>
            <input
                type="password"
                id="administrator_password"
                placeholder="********"
                autocomplete="off"
                v-model="administrator.password"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.password.$touch()"
            />
            <p
                v-if="!$v.administrator.password"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input"
            :class="{ invalid: $v.administrator.password_confirmation.$error }"
            >
            <label
                for="administrator_password_confirmation"
                class="block text-sm font-medium"
            >
                تاكيد كلمة المرور
            </label>
            <input
                type="password"
                id="administrator_password_confirmation"
                placeholder="********"
                v-model="administrator.password_confirmation"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.password_confirmation.$touch()"
            />
            <p
                v-if="!$v.administrator.password_confirmation.sameAsPassword"
                class="text-xs text-red-500 mt-2"
            >
                يجب ان تكون كلمة المرور متطابقة
            </p>
            </div>

          
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              إضافة
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required, minLength, sameAs  } from "vuelidate/lib/validators";
import AdministratorsService from "@/services/AdministratorsService";

export default {
  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,
      administrator: {
        username: "",
        full_name: "",
        type: "",
       
        password: "",
        password_confirmation: "",

        roles: {},
      },

     
      // loader: "dots",
    };
  },
  created() {

    this.administrator.username = ""
    this.administrator.full_name = ""
    this.administrator.type = ""
   
    this.administrator.password = ""


  },
  validations: {
    administrator: {
      full_name: {
        required,
        minLength: minLength(2),
      },
      username: {
        required,
        minLength: minLength(2),
      },
      type: {
        required,
      },
    
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
   

    addAdministrator() {
        let loader = this.$loading.show({
            loader: this.loader,
        });

        let data = {
          username: this.administrator.username,
          name: this.administrator.full_name,
          type: Number(this.administrator.type),
          password: this.administrator.password,
        };

        loader.hide();

      AdministratorsService.createAdministrator(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({ name: "administrators" });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>

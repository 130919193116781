<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        صرف لقاح للمسجل &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="underline font-extrabold">{{ giving.citizen_name }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="underline font-extrabold"
          >{{ day }} / {{ month }} / {{ year }}</span
        >
      </h1>

      <div class="cursor-pointer text-white">
        <button
          @click.prevent="next_vaccination_model = true"
          type="button"
          class="bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-offset-2 focus:ring-green-500 border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
        >
          تسجيل موعد التطعيمة القادمة
        </button>
      </div>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-2 gap-8">



          <div class="col-span-2 text-white">

            <p class="text-base font-semibold text-slate-900 dark:text-white">
            يوم تلقي اللقاح
          </p>
          <div class="mt-2 flex justify-between items-center">
            <button type="button"
              @click="vaccination_date_flag_fun(1)"
              :class="
                giving.vaccination_date_flag == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/5 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              اليوم
            </button>

            <button type="button"
              @click="vaccination_date_flag_fun(2)"
              :class="
                giving.vaccination_date_flag == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/5 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              قبل يوم
            </button>

            <button type="button"
              @click="vaccination_date_flag_fun(3)"
              :class="
                giving.vaccination_date_flag == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/5 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
            قبل يومين
            </button>

            <button type="button"
              @click="vaccination_date_flag_fun(4)"
              :class="
                giving.vaccination_date_flag == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/5 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
            قبل ثلاث ايام
            </button>
            <button type="button"
              @click="vaccination_date_flag_fun(5)"
              :class="
                giving.vaccination_date_flag == 5
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/5 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
            قبل اربعة ايام
            </button>
          </div>


          </div>






          <div class="input">
            <label for="vaccine_id" class="block text-sm font-medium">
              اختر القاح
            </label>
            <select
              id="vaccine_id"
              name="vaccine_id"
              v-model="vaccine_id_selected"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option disabled selected>القاح</option>
              <option
                v-for="vaccine in vaccines_data"
                :key="vaccine.id"
                v-bind:value="vaccine.vaccineid"
                class="text-lg"
              >
                {{ vaccine.vaccine_name_ar }}
                &nbsp; &nbsp;
                {{ vaccine.vaccine_name }}
              </option>
            </select>
          </div>

          <div v-if="age_groups_length > 0" class="input">
            <label for="fixed_vaccines_id" class="block text-sm font-medium">
              الفئة العمرية
            </label>
            <select
              id="fixed_vaccines_id"
              name="fixed_vaccines_id"
              v-model="giving.fixed_vaccines_id"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option disabled selected>القاح</option>
              <option
                v-for="age in age_groups_data"
                :key="age.id"
                v-bind:value="age.id"
                class="text-lg"
              >
                {{ age.description }}
              </option>
            </select>
          </div>
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              @click.prevent="addGivingVaccine"
              v-if="vaccine_id_to_edit == ''"
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
            صرف 
            </button>

            <button
              @click.prevent="updateGivingVaccine"
              v-if="vaccine_id_to_edit != ''"
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              تعديل
            </button>
          </div>
        </div>
      </form>

      <div class="py-2 align-middle inline-block min-w-full mt-6">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <div
              class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
            >
              <div scope="col" class="w-1/12 px-4 py-3 hidden lg:block">
                الرقم
              </div>
              <div scope="col" class="w-7/12 md:w-4/12 px-2 py-3">اللقاح</div>
              <div
                scope="col"
                class="w-/12 md:w-1/12 px-2 py-3 hidden md:block"
              >
                الفئة
              </div>

              <div
                scope="col"
                class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block"
              >
                مركز التطعييم
              </div>

              <div
                scope="col"
                class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block"
              >
                التاريخ
              </div>

              <div
                scope="col"
                class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block"
              >
                الساعة
              </div>
            </div>


            <div v-if="there_is_vaccine" class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center">
                لاتوجد تطعيمات
            </div>

            <div
                v-else
              v-for="(obtained, personIdx) in obtained_vaccines_data"
              :key="obtained.id"
              class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
              :class="
                personIdx % 2 === 0
                  ? 'bg-white dark:bg-slate-800'
                  : 'bg-gray-50 dark:bg-slate-800'
              "
            >
              <div
                class="w-1/12 px-4 py-4 whitespace-nowrap text-sm font-medium hidden md:block"
              >
                {{ personIdx + 1 }}
              </div>

              <div
                class="w-7/12 md:w-4/12 px-2 py-4 whitespace-nowrap text-sm truncate"
              >
                {{ obtained.vaccine_name_en }}
              </div>

              <div
                class="w-7/12 md:w-1/12 px-2 py-4 whitespace-nowrap text-sm truncate"
              >
                {{ obtained.description }}
              </div>
              <div
                class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
              >
                {{ obtained.health_center_name }}
              </div>

              <div
                class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
              >
                {{ obtained.date }}
              </div>

              <div
                class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:flex justify-between"
              >
                {{ obtained.time }}

                <button
                  title="خدف"
                  @click="deleteGivingVaccine(obtained.id)"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-red-600"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.80444 6.50317H5.80444H21.8044"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </table>
        </div>
      </div>






    </div>

    <div
      v-if="next_vaccination_model"
      class="absolute z-50 inset-0 bg-black bg-opacity-70"
    >
      <div class="flex justify-center items-center h-full">
        <div class="bg-white w-1/4 h-64">
          <div class="flex justify-between items-center border-b-2 py-2">
            <div class="px-4 text-black">تسجيل موعد التطعيمة القادمة</div>

            <div class="cursor-pointer text-red-600">
              <button
                @click.prevent="next_vaccination_model = false"
                type="button"
                class="w-12 py-2 font-bold focus:outline-none"
              >
                X
              </button>
            </div>
          </div>

          <div class="w-64 mx-auto mt-10">
            <div class="flex justify-between items-center">
              <div
                class="input flex flex-col"
                :class="{ invalid: $v.next_vaccination_day.$error }"
              >
                <label
                  for="citizen_date_of_birth_day"
                  class="text-sm font-medium"
                >
                  اليوم <span class="text-red-500">*</span>
                </label>

                <input
                  type="number"
                  id="citizen_date_of_birth_day"
                  placeholder=".."
                  v-model="next_vaccination_day"
                  class="mt-1 w-16 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  @blur="$v.next_vaccination_day.$touch()"
                />
              </div>

              <div
                class="input flex flex-col"
                :class="{ invalid: $v.next_vaccination_month.$error }"
              >
                <label
                  for="citizen_date_of_birth_month"
                  class="text-sm font-medium"
                >
                  الشهر <span class="text-red-500">*</span>
                </label>

                <input
                  type="number"
                  id="citizen_date_of_birth_month"
                  placeholder=".."
                  v-model="next_vaccination_month"
                  class="mt-1 w-16 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  @blur="$v.next_vaccination_month.$touch()"
                />
              </div>

              <div
                class="input flex flex-col"
                :class="{ invalid: $v.next_vaccination_year.$error }"
              >
                <label
                  for="citizen_date_of_birth_year"
                  class="text-sm font-medium"
                >
                  السنة <span class="text-red-500">*</span>
                </label>

                <input
                  type="number"
                  id="citizen_date_of_birth_year"
                  placeholder="...."
                  v-model="next_vaccination_year"
                  class="mt-1 w-24 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  @blur="$v.next_vaccination_year.$touch()"
                />
              </div>
            </div>
          </div>

          <div
            class="w-full flex justify-center mt-8 text-white"
            :class="
              next_vaccination_year == '' ||
              next_vaccination_month == '' ||
              next_vaccination_day == ''
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            "
          >
            <button
              @click.prevent="add_next_vaccination()"
              v-if="vaccine_id_to_edit == ''"
              :disabled="
                next_vaccination_year == '' ||
                next_vaccination_month == '' ||
                next_vaccination_day == ''
              "
              type="submit"
              class="mx-auto border border-transparent rounded-md shadow-sm py-2 px-16 text-sm font-medium focus:outline-none"
              :class="
                next_vaccination_year == '' ||
                next_vaccination_month == '' ||
                next_vaccination_day == ''
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              تسجيل
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required, between } from "vuelidate/lib/validators";
import GivingVaccineService from "@/services/GivingVaccineService";

import VaccinationsStorageService from "@/services/VaccinationsStorageService";

export default {
  components: {
    errormessage,
  },
  data() {
    return {

      
      next_vaccination_model: false,
      next_vaccination_year: "",
      next_vaccination_month: "",
      next_vaccination_day: "",


      errors: null,

      vaccines_data: {},
      obtained_vaccines_data: {},
      day: this.$route.params.day,
      month: this.$route.params.month,
      year: this.$route.params.year,

      

      giving: {
        citizen_name: this.$route.params.citizen_name,
        citizenid: this.$route.params.id,
        health_centerid: this.$route.params.vaccination_center_id,
        vaccineid: "",
        fixed_vaccines_id: "",
        vaccination_date_flag : 1,
      },

      vaccine_id_to_edit: "",

      vaccine_id_selected: "",

      age_groups_data: {},

      age_groups_length: 0,

      there_is_vaccine: true,
    };
  },

  created() {
    this.getVaccines();
    this.getObtainedVaccinese();
  },

  validations: {
    giving: {
      vaccineid: {
        required,
      },
      fixed_vaccines_id: {
        required,
      },
    },

    next_vaccination_year: {
      between: between(1990, 3000),
    },

    next_vaccination_month: {
      between: between(1, 12),
    },

    next_vaccination_day: {
      between: between(1, 31),
    },
  },

  watch: {
    vaccine_id_selected: function () {
      this.age_groups_data = {};
      this.age_groups_length = 0;

      this.giving.fixed_vaccines_id = "";
      this.giving.vaccineid = this.vaccine_id_selected;

      this.getTheAgeGroupsForTheVaccine(this.vaccine_id_selected);
    },
  },

  methods: {

    vaccination_date_flag_fun(r) {
      this.giving.vaccination_date_flag = r;
    },

    add_next_vaccination(){

        this.errors = null;
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        citizen_id: this.giving.citizenid,
        year: Number(this.next_vaccination_year),
        month: Number(this.next_vaccination_month),
        day: Number(this.next_vaccination_day),
      };

      GivingVaccineService.next_vaccination(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();

            this.next_vaccination_model = false

            this.vaccine_id_selected = "";
            this.giving.vaccineid = "";
            this.giving.fixed_vaccines_id = "";

            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getObtainedVaccinese();
            // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.next_vaccination_model = false
          this.errors = err.response.data.message;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getTheAgeGroupsForTheVaccine() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      GivingVaccineService.getTheAgeGroupsForTheVaccine(
        this.vaccine_id_selected
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.age_groups_data = res.data;

            this.age_groups_length = this.age_groups_data.length;

            // if(this.age_groups_data.length == 1){
            //     this.giving.fixed_vaccines_id = this.age_groups_data[0].id
            //     this.age_groups_length = 0
            // }
            // console.log(this.age_groups_data[0])
          }, 10);
        })
        .catch(() => {
          loader.hide();
        });
    },

    edit(id, vaccineid) {
      this.vaccine_id_to_edit = id;
      this.giving.vaccineid = vaccineid;
    },

    getObtainedVaccinese() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      GivingVaccineService.getObtainedVaccineseWithoutPagination(
        this.$route.params.id
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.there_is_vaccine = false

            this.obtained_vaccines_data = res.data.data;

            console.log(this.obtained_vaccines_data);
          }, 10);
        })
        .catch(() => {
          loader.hide();

          this.there_is_vaccine = true
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "...عذرا",
        //     text: ".لم يتم صرف اي لقاح بعد",
        //   });
        });
    },

    getVaccines() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      VaccinationsStorageService.getVaccinationsStorageWithoutRepeat(
        this.giving.health_centerid
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.vaccines_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    deleteGivingVaccine(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      // let data = {
      //     id: this.vaccine_id_to_edit,
      //     citizenid: this.giving.citizenid,
      //     health_centerid: this.giving.health_centerid,
      //     vaccineid: this.giving.vaccineid,
      //     fixed_vaccines_id: this.giving.fixed_vaccines_id,
      // }

      GivingVaccineService.deleteGivingVaccine(id)

        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getObtainedVaccinese();
            // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },

    updateGivingVaccine() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        id: this.vaccine_id_to_edit,
        citizenid: this.giving.citizenid,
        health_centerid: this.giving.health_centerid,
        vaccineid: this.giving.vaccineid,
        fixed_vaccines_id: this.giving.fixed_vaccines_id,
      };

      GivingVaccineService.updateGivingVaccine(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getObtainedVaccinese();
            // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },

    addGivingVaccine() {
        this.errors = null;
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        citizenid: this.giving.citizenid,
        health_centerid: this.giving.health_centerid,
        vaccineid: this.giving.vaccineid,
        fixed_vaccines_id: this.giving.fixed_vaccines_id,
        vaccination_date_flag : this.giving.vaccination_date_flag,
      };

      GivingVaccineService.givingVaccine(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();

            this.vaccine_id_selected = "";
            this.giving.vaccineid = "";
            this.giving.fixed_vaccines_id = "";

            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getObtainedVaccinese();
            // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.errors = err.response.data.message;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>

import AuthService from "../../services/AuthService";

export const namespaced = true;

export const state = {
    accessToken: null,
    administrator: null,
    loggingIn: false,
    loginError: null
};

export const mutations = {
    loginStart: state => state.loggingIn = true,

    loginStop: (state, errorMessage) => {
        state.loggingIn = false;
        state.loginError = errorMessage;
    },

    updateAccessToken: (state, accessToken) => {
        state.accessToken = accessToken;
    },

    updateAdministrator: (state, administrator) => {
        state.administrator = administrator;
    },


};

export const actions = {

    async doLogin({ commit }, loginData) {
        commit('loginStart');

        try {
            const AuthResp = await AuthService.login(loginData);

            // 䫀笢
            // localStorage.setItem('accessToken', AuthResp.data.token);
            commit('loginStop', null);
            commit('updateAccessToken', AuthResp.data.token);
            commit('updateAdministrator', AuthResp.data.user);

            return AuthResp;

        } catch (error) {
            commit('loginStop', error.response.data.message);
            commit('updateAccessToken', null);
            commit('updateAdministrator', null);
        }
    },

    fetchAccessToken({ commit }) {
        commit('updateAccessToken', localStorage.getItem('accessToken'));
    }
};

export const getters = {};